import React, { ReactElement, useState, useEffect} from "react";
import { navigate } from "gatsby";

import { Loader, handleLogin, isLoggedIn, InternalAbsPath } from "@chweb/commonui";

type LoginFormProps = {
    handleLogin: (login: string, password: string) => Promise<void>
}

function LoginForm({ handleLogin }: LoginFormProps): React.ReactElement {
    const [ login, setLogin] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');

    const [loginError, setLoginError] = useState<string>('');
    const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

    const validateLoginEntriesAndLogin = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        setLoginInProgress(true);

        const handleError = (msg: string) => {
            console.log(`Error during login: ${msg}`);
            setLoginError(msg);
        }

        if(!login.trim())
        {
            setLoginInProgress(false);
            return handleError("L'e-mail ne peut pas être laissé vide.");
        }

        if (!password.trim())
        {
            setLoginInProgress(false);
            return handleError("Le mot de passe ne peut pas être laissé vide.");
        }

        handleLogin(login, password).then(() => {
            console.log("Successful login.");
            setLoginError('');
            navigate(InternalAbsPath);
        }).catch(() => {
            handleError("Votre e-mail ou votre mot de passe est invalide. Veuillez vérifier puis corriger vos entrées.");
        }).finally(() => {
            setLoginInProgress(false);
        });
    };

    return (
        <div className="w3-container">
            <form className="w3-card w3-panel w3-padding-32" onSubmit={validateLoginEntriesAndLogin} >
                <fieldset disabled={loginInProgress} style={{border: 0}}>
                    <label>
                        E-mail
                        <input type="text" className="w3-input" name="username" onChange={event => setLogin(event.target.value)} />
                    </label>
                    <label>Mot de passe
                        <input type="password" className="w3-input" name="password" onChange={event => setPassword(event.target.value)} />
                    </label>
                </fieldset>
                {
                    loginError
                        ? <p className="w3-panel w3-red">{loginError}</p>
                        : null
                }
                {
                    loginInProgress
                        ? <Loader />
                        : <div className="w3-padding-16">
                            <input type="submit" className="w3-button cpcolor-brown" value="Se connecter" />
                        </div>
                }
            </form>
        </div>
    );
}

type LoginComponentProps = Record<string, never>;

export default function LoginComponent({}: LoginComponentProps): ReactElement {

    // if already logged in, no point of showing the form
    useEffect(() => {
        if (isLoggedIn()) {
            navigate(InternalAbsPath);
        }
    });

    return (
        <LoginForm handleLogin ={ handleLogin } />
    );
}