import React from "react";
import Layout from "../components/layout";

import LoginComponent from "../components/internal-login";
import ChwebContext from "../components/context";
import { LocationInfo } from "../libs/location";

interface LoginScreenProps {
    location: LocationInfo
}

export default function LoginScreen({location}: LoginScreenProps) {
    return <Layout locale={"fr"} location={location}>
        <ChwebContext />
        <LoginComponent />
    </Layout>;
}