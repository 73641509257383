import React from "react";
import * as ReactDOM from "react-dom";

import { ChwebContext } from "@chweb/commonui";

const devEnvTag = process.env.GATSBY_ENV_TAG;

export default function ContextWidget() {
    ChwebContext.makeIfNotValid({ react: React, reactDOM: ReactDOM }, devEnvTag);
    return <></>;
}